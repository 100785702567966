import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import SolutionsContent from "../components/content/solutions"
import Footer from "../components/footer"

const SolutionsPage = () => (
  <>
    <SEO title="Solutions" />
    <Nav />
    <SolutionsContent />
    <Footer />
  </>
)

export default SolutionsPage
